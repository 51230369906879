<template>
  <div class="activity">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "Activity",
};
</script>

<style lang="scss" scoped></style>
